import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import MainImg from "components/MainImg";
import Testimonials from 'components/Testimonials';

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <MainImg />
      <hr />
      <Testimonials />
    </Layout>
  );
};

export default IndexPage;
