import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';


const MainImg = () => {
  
    const { placeholderImage } = useStaticQuery(graphql`
        query {
            placeholderImage: file(relativePath: { eq: "disp.jpeg" }) {
                childImageSharp {
                    fluid(maxWidth: 2500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    const logoImage = placeholderImage.childImageSharp.fluid;


    return (
        <Img fluid={logoImage} alt="Planet Pickle" />
    );
};

export default MainImg;
