import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Testimonials = styled.div`
  ${tw`max-w-screen-sm mx-auto w-full px-0 sm:px-16 mb-4`};
`;

export const Testimonial = styled.div`
  ${tw`flex flex-col items-center text-center`};
`;

export const Title = styled.h3`
  ${tw`font-semibold my-4 text-lg text-green-500`};
`;
